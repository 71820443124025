<template>
   <b-col cols="12">
      <h2>La única comunidad online de técnicos en España</h2>
      <p>¿Ya eres miembro? <b-link :to="{ name: 'login'}">Inicia sesión</b-link></p>
      <social-networks-auth></social-networks-auth>
      <p class="text-l mb-2">Registrate usando tu correo electrónico</p>
      <b-alert fade :show="showFormErrorAlert" variant="danger"> {{ this.errorFormMessage }}</b-alert>
      <b-row>
      <b-col>
         <validation-provider name="nombre" vid="firstname" rules="required" v-slot="validationContext">
            <b-form-group id="input-firstname" label-for="input-firstname">
            <b-form-input
               id="input-firstname"
               name="input-firstname"
               v-model="register.firstname"
               :state="getValidationState(validationContext)"
               aria-describedby="input-1-live-feedback"
               placeholder="Nombre"
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
         </validation-provider>
      </b-col>
      </b-row>
      <b-row>
      <b-col>
         <validation-provider name="apellido" vid="lastname" rules="required" v-slot="validationContext">
            <b-form-group id="input-username" label-for="input-username">
            <b-form-input
               id="input-lastname"
               name="input-lastname"
               v-model="register.lastname"
               :state="getValidationState(validationContext)"
               aria-describedby="input-1-live-feedback"
               placeholder="Apellido"
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
         </validation-provider>
      </b-col>
      </b-row>
      <b-row>
      <b-col>
         <validation-provider name="correo electrónico" vid="email" rules="required|email" v-slot="validationContext">
            <b-form-group id="input-username" label-for="input-username">
            <b-form-input
               id="input-username"
               name="input-username"
               v-model="register.username"
               :state="getValidationState(validationContext)"
               aria-describedby="input-1-live-feedback"
               placeholder="Correo electrónico"
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
         </validation-provider>
      </b-col>
      </b-row>
      <b-row>
      <b-col>
         <validation-provider name="password" v-slot="validationContext" vid="password" rules="required|password">
            <b-form-group id="input-password" label-for="input-password">
            <b-form-input
               id="input-password"
               name="input-password"
               type="password"
               v-model="register.password"
               :state="getValidationState(validationContext)"
               aria-describedby="input-password"
               placeholder="Contraseña"
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
         </validation-provider>
      </b-col>
      </b-row>
      <b-row>
      <b-col>
         <validation-provider name="confirma tu contraseña" rules="required|confirmed:password" vid="repeatedConfirmation" v-slot="validationContext">
            <b-form-group id="input-repeated-password" label-for="input-repeated-password">
            <b-form-input
               id="input-repeated-password"
               name="input-repeated-password"
               type="password"
               v-model="register.repeated_password"
               :state="getValidationState(validationContext)"
               aria-describedby="input-repeated-password"
               placeholder="Confirma tu contraseña"
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
         </validation-provider>
      </b-col>
      </b-row>
      <b-row>
      <b-col>
         <validation-provider name="términos y condiciones" rules="is:true" v-slot="validationContext">
            <b-form-group>
            <b-form-checkbox
               id="register-terms"
               v-model="register.terms"
               :state="getValidationState(validationContext)"
               name="terms-and-conditions"
               class="avify-checkbox"
               value=true
               unchecked-value=false
               required
            >
               Al registrarte, estarás aceptando la
               <b-link href="https://avify.net/politica-de-privacidad/" target="_blank">política de privacidad</b-link> y los
               <b-link href="https://avify.net/terminos-y-condiciones-generales/" target="_blank">términos y condiciones</b-link> de Avify

            </b-form-checkbox>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
         </validation-provider>
      </b-col>
      </b-row>
      <div class="text-center">
      <b-button block type="submit" variant="primary" class="full-width my-4">
         Crea tu cuenta
      </b-button>
      </div>
   </b-col>
</template>
<script>
/* eslint vue/no-mutating-props: 0 */

import SocialNetworksAuth from '../SocialNetworksAuth'

export default {
  name: 'RegisterCommonCard',
  components: {
    SocialNetworksAuth
  },
  props: {
    register: {
      type: Object,
      required: true
    },
    errorFormMessage: {
      type: String,
      required: false
    }
  },
  computed: {
    showFormErrorAlert () {
      return this.errorFormMessage !== null
    }
  }
}
</script>
