<template>
   <div>
      <div v-if="showGoBack">
         <b-link
            @click="$emit('onGoToStart')"
            class="text-light">
               &lt; Inicio | Registro técnico
         </b-link>
      </div>
      <div class="login-header mt-4 h-100">
         <h2>Completar mi información de técnico</h2>
         <p class="mb-2">
            Esta información no es obligatoria, pero sí es necesaria para
            poder aparecer en las búsquedas y recibir ofertas ajustadas a
            tu perfil.
         </p>
         <b-alert fade :show="showFormErrorAlert" variant="danger">
            {{ errorFormMessage }}
         </b-alert>
         <b-row>
            <b-card-header>
               <div class="avatar-container d-flex align-items-end">
                  <img v-if="register.avatarImage" :src="buildImage"
                     alt="avatar"
                     class="user-avatar img-thumbnail img-fluid"/>
                  <img v-else src="/img/default-user-avatar.svg"
                     alt="avatar"
                     class="user-avatar img-thumbnail img-fluid" />
                  <b-link class="edit-photo" @click="onClickUploadPhoto">
                     <img src="../../assets/icons/edit_card.svg">
                  </b-link>
               </div>
               <modal-component
                  id="upload-photo-modal"
                  title="Sube tu foto"
                  v-bind:confirmationClose="false"
                  hideFooter
               >
                <avatar-image-upload-simple
                  @onCancelUploadAvatarFile="closeImageModal"
                  @onChangeUploadAvatarFile="putImage"
                  :avatarImgUrl="getAvatarUrl">
                </avatar-image-upload-simple>
               </modal-component>
            </b-card-header>
         </b-row>
         <b-row>
            <b-col>
               <b-form-group id="input-sectors" label-for="input-sectors">
                  <ProfessionalSkillSelect
                     title="Sectores"
                     placeholder="Selecciona uno o varios sectores"
                     :options="sectorOptions"
                     :suggestions="true"
                     suggestionItem="sector"
                     :selectedOptions="register.sectors"
                     eventName="removeSector"
                     @onRemoveSector="onRemoveSector"
                     addEventName="addNewSector"
                     @addNewSector="onAddSector"
                     :are-options-loaded="sectorOptions.length === 0"
                     v-bind:showHeader="false"
                  />
               </b-form-group>
            </b-col>
         </b-row>
         <b-row>
            <b-col>
               <b-form-group id="input-roles" label-for="input-roles">
                  <ProfessionalSkillSelect
                     title="Roles"
                     placeholder="Selecciona uno o varios roles"
                     :options="rolesOptions"
                     :suggestions="true"
                     suggestionItem="rol"
                     :selectedOptions="register.roles"
                     eventName="removeRole"
                     @onRemoveRole="onRemoveRole"
                     addEventName="addNewRole"
                     @addNewRole="onAddRole"
                     :are-options-loaded="rolesOptions.length === 0"
                     v-bind:showHeader="false"
                  />
               </b-form-group>
            </b-col>
         </b-row>
         <b-row>
            <b-col>
               <b-form-group id="input-skills" label-for="input-skills">
                  <ProfessionalSkillSelect
                     title="Habilidades"
                     placeholder="Selecciona uno o varios habilidades"
                     :options="skillsOptions"
                     :suggestions="true"
                     suggestionItem="habilidad"
                     :selectedOptions="register.skills"
                     eventName="removeSkill"
                     @onRemoveSkill="onRemoveSkill"
                     addEventName="addNewSkill"
                     @addNewSkill="onAddSkill"
                     :are-options-loaded="skillsOptions.length === 0"
                     v-bind:showHeader="false"
                  />
               </b-form-group>
            </b-col>
         </b-row>
         <div class="text-center">
            <b-button block type="submit" variant="primary" class="full-width my-4">
               Finalizar
            </b-button>
         </div>
      </div>
   </div>
</template>
<script>
/* eslint vue/no-mutating-props: 0 */

import AvatarImageUploadSimple from '../ImageUpload/AvatarImageUploadSimple'
import ModalComponent from '../Modal/ModalComponent'
import ProfessionalSkillSelect from '../ProfessionalSkillSelect/ProfessionalSkillSelect'

export default {
  components: { AvatarImageUploadSimple, ModalComponent, ProfessionalSkillSelect },
  props: {
    register: Object,
    showGoBack: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      errorFormMessage: null
    }
  },
  computed: {
    showFormErrorAlert () {
      return this.errorFormMessage !== null
    },
    sectorOptions () {
      return this.$store.getters.getSectorsList
    },
    rolesOptions () {
      return this.$store.getters.getRolesList
    },
    skillsOptions () {
      return this.$store.getters.getSkillsList
    },
    buildImage () {
      return 'data:image/' +
        this.register.avatarExtension +
        ';base64, ' +
        this.register.avatarImage
    },
    getAvatarUrl () {
      return '/img/default-user-avatar.svg'
    }
  },
  methods: {
    onClickUploadPhoto () {
      this.$bvModal.show('upload-photo-modal')
    },
    closeImageModal () {
      this.$nextTick(() => {
        this.$bvModal.hide('upload-photo-modal')
      })
    },
    putImage (data) {
      this.register.avatarImage = data.base64
      this.register.avatarExtension = data.extension
      this.$nextTick(() => {
        this.$bvModal.hide('upload-photo-modal')
      })
    },
    onRemoveSector (id) {
      this.register.sectors.forEach((sector, index) => {
        if (sector.id === id) {
          this.register.sectors.splice(index, 1)
        }
      })
    },
    onRemoveRole (id) {
      this.register.roles.forEach((role, index) => {
        if (role.id === id) {
          this.register.roles.splice(index, 1)
        }
      })
    },
    onRemoveSkill (id) {
      this.register.skills.forEach((skill, index) => {
        if (skill.id === id) {
          this.register.skills.splice(index, 1)
        }
      })
    },
    onAddSector (sector) {
      const exists = this.register.sectors.findIndex(
        item => item.id === sector.id
      )
      if (sector.id !== '' && exists === -1) {
        this.register.sectors.push(sector)
      }
    },
    onAddSkill (skill) {
      const exists = this.register.skills.findIndex(
        item => item.id === skill.id
      )
      if (skill.id !== '' && exists === -1) {
        this.register.skills.push(skill)
      }
    },
    onAddRole (role) {
      const exists = this.register.roles.findIndex(
        item => item.id === role.id
      )
      if (role.id !== '' && exists === -1) {
        this.register.roles.push(role)
      }
    }
  }
}
</script>
<style lang="less">
  .avatar-container {
     position: relative;
     .user-avatar {
       max-width: 124px;
     }
     .edit-photo {
        position: absolute;
        bottom: 5px;
        right: 5px;
     }
  }
</style>
