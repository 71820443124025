<template>
  <b-container fluid class="min-vh-100">
    <b-row class="min-vh-100" align-h="center" align-v="stretch">
      <b-col cols="12" lg="6" class="column-image" order="1" order-lg="2">
        <div class="d-flex justify-content-center align-items-center h-100">
          <b-row class="justify-content-center">
            <b-col cols="6" md="8" lg="12">
              <img alt="Avify Logo" src="../assets/logos/logo_full_big.svg" class="p-3 p-md-2 p-lg-0 img-fluid" />
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="12" md="8" lg="6" order="2" order-lg="1">
        <b-row align-h="center" align-v="center" class="h-100" v-if="registerStep === 3">
          <b-col col xs="12" sm="8">
            <email-sent-card
              :username="register.username"
            ></email-sent-card>
          </b-col>
        </b-row>
        <b-row align-h="center" align-v="center" class="h-100" v-if="registerStep !== 3">
          <b-col cols="12" lg="9" class="text-left">
            <div class="login-header my-5 h-100">
              <validation-observer ref="registerForm" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onRegister)"
                  autocomplete="off" novalidate>
                  <b-row v-if="registerStep === 1">
                    <register-common-card
                      :register="register"
                      :errorFormMessage="errorFormMessage"
                    ></register-common-card>
                  </b-row>
                  <b-row align-h="center" align-v="center" class="h-100" v-if="registerStep === 2">
                    <b-col cols="12">
                      <onboarding-business-card
                        :register="register"
                        :registerStep="registerStep"
                        @onGoToStart="goBack"
                      ></onboarding-business-card>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
              <hr/>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import EmailSentCard from '../components/Registration/EmailSentCard'
import OnboardingBusinessCard from '../components/Registration/OnboardingBusinessCard'
import RegisterCommonCard from '../components/Registration/RegisterCommonCard'

export default {
  name: 'Register',
  components: { EmailSentCard, OnboardingBusinessCard, RegisterCommonCard },
  data () {
    return {
      register: {
        firstname: null,
        lastname: null,
        username: null,
        password: null,
        repeated_password: null,
        business_name: null,
        business_cif: null,
        business_sector: [],
        business_address: null,
        business_zipcode: null,
        business_prefix: null,
        business_phone: null,
        business_location: null
      },
      registerStep: 1,
      errorFormMessage: null,
      forceContinue: false,
      cityHasError: false
    }
  },
  methods: {
    goBack () {
      this.registerStep = 1
    },
    stay (modalId) {
      this.$nextTick(() => {
        this.$bvModal.hide(modalId)
      })
    },
    runCustomValidations () {
      let results = true
      if (this.register.business_location === null) {
        results = false
      }
      return results
    },
    onRegister () {
      this.lockScreen()
      this.errorFormMessage = null
      if (this.registerStep === 1) {
        this.registerStep = 2
        this.unlockScreen()
        return
      }

      if (this.registerStep === 2 && !this.runCustomValidations()) {
        this.unlockScreen()
        return
      }

      this.$store.dispatch('registerUserBusiness', this.register)
        .then(() => {
          this.registerStep = 3
          this.unlockScreen()
        })
        .catch((error) => {
          this.registerStep = 1
          const responseData = error.response.data
          this.errorFormMessage = this.createFormErrors(responseData)

          setTimeout(() => {
            const responseErrors = this.createFormFieldErrors(responseData)
            this.$refs.registerForm.setErrors(responseErrors)
          }, 100)
          this.unlockScreen()
        })
    }
  }
}
</script>

<style lang="less">
  @import 'register.less';

  .column-image {
    background-image: url("../assets/img/login/background-login.jpg");
    background-size: cover;
  }
</style>
