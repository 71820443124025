<template>
  <div class="Image-avatar-upload pb-4">
    <div id="avatar-croppie"></div>
    <div id="upload-wrapper">
      <div class="Modal">
        <div class="input-file">
          <input
            type="file"
            class="d-none"
            id="upload-avatar-image"
            ref="upload-avatar-image"
            @change="setUpFileUploader"
            accept="image/jpeg, image/png"
          >
        </div>
        <b-row class="upload-image-actions justify-content-center">
          <div class="d-flex justify-content-between w-75">
            <a class="btn btn-secondary" @click="$emit('onCancelUploadAvatarFile')">Cancelar</a>
            <a class="btn btn-secondary" @click="selectImage">
              <b-icon-upload />
            </a>
            <a class="btn btn-primary" @click="uploadFile">Subir</a>
          </div>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Croppie from 'croppie'
import { BIconUpload } from 'bootstrap-vue'

export default {
  name: 'AvatarImageUploadSimple',
  components: {
    BIconUpload
  },
  props: {
    avatarImgUrl: {
      type: String,
      required: false,
      default: '/img/default-user-avatar.svg'
    }
  },
  data () {
    return {
      image: null,
      croppie: null
    }
  },
  mounted () {
    this.$on('imageUploaded', function (imageData) {
      this.img = imageData
      this.croppie.destroy()
      this.setUpCroppie(imageData)
    })
    this.image = this.avatarImgUrl
    this.setUpCroppie()

    this.$refs['upload-avatar-image'].click()
  },
  methods: {
    setUpCroppie () {
      const el = document.getElementById('avatar-croppie')
      this.croppie = new Croppie(el, {
        viewport: { width: 180, height: 180 },
        boundary: { width: 200, height: 200 },
        showZoomer: true,
        enableOrientation: false
      })
      this.croppie.bind({
        url: this.image
      })
    },
    setUpFileUploader (evt) {
      const files = evt.target.files || evt.dataTransfer.files
      if (!files.length) {
        return
      }

      this.createImage(files[0])
    },
    createImage (file) {
      // const image = new Image()
      const reader = new FileReader()
      const vm = this

      reader.onload = (e) => {
        vm.image = e.target.result
        vm.$emit('imageUploaded', e.target.result)
      }
      reader.readAsDataURL(file)
    },
    uploadFile () {
      this.croppie.result({
        type: 'canvas',
        size: 'original'
      })
        .then(response => {
          this.image = response
          let [type, data] = response.split(',')
          type = type.replace(';base64', '')
          const [, extension] = type.split('/')
          this.$emit('onChangeUploadAvatarFile', { base64: data, extension: extension })
        })
    },
    selectImage () {
      this.$refs['upload-avatar-image'].click()
    }
  }
}
</script>

<style lang="less">
  .Image-avatar-upload {
    .Modal {
      margin-top: 10px;
    }
  }
</style>
