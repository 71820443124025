<template>
  <b-container fluid class="min-vh-100">
    <b-row class="min-vh-100" align-h="center" align-v="stretch">
      <b-col cols="12" lg="6" class="column-image" order="1" order-lg="2">
        <div class="d-flex justify-content-center align-items-center h-100">
          <b-row class="justify-content-center">
            <b-col cols="6" md="8" lg="12">
              <img
                alt="Avify Logo"
                src="../assets/logos/logo_full_big.svg"
                class="p-3 p-md-2 p-lg-0 img-fluid" />
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="12" md="8" lg="6" order="2" order-lg="1">
        <b-row align-h="center" align-v="center" class="h-100">
          <b-col cols="12" lg="9" class="text-left">
            <div class="login-header my-5 h-100">
              <validation-observer ref="registerForm" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onRegister)"
                  autocomplete="off" novalidate>
                  <b-row align-h="center" align-v="center" class="h-100">
                    <b-col cols="12">
                      <onboarding-technician-card
                        :register="register"
                        v-bind:showGoBack="false"
                      ></onboarding-technician-card>
                      <modal-component
                          id="uncomplete-profile-warning"
                          title="Completa tu perfil"
                          okTitle="Volver"
                          v-bind:confirmationClose="false"
                          deleteButton
                          deleteText="Continuar"
                          @confirmed="stay"
                          @deleted="createUser"
                      >
                        <template>
                          <b-row>
                            <b-col>
                              <p text-center>
                                Esta información te ayudará a que aparezcas en
                                el buscador
                              </p>
                            </b-col>
                          </b-row>
                        </template>
                      </modal-component>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
              <hr/>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import OnboardingTechnicianCard from '../components/Registration/OnboardingTechnicianCard'
import ModalComponent from '../components/Modal/ModalComponent'
import EditableUserFactory from '@/domain/services/EditableUserFactory'

export default {
  name: 'CompleteTechnician',
  components: { OnboardingTechnicianCard, ModalComponent },
  data () {
    return {
      register: {
        avatarImage: null,
        avatarExtension: null,
        sectors: [],
        roles: [],
        skills: []
      },
      errorFormMessage: null,
      forceContinue: false
    }
  },
  created () {
    this.$store.dispatch('getSectors')
    this.$store.dispatch('getRoles')
    this.$store.dispatch('getSkills')
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    }
  },
  methods: {
    stay (modalId) {
      this.$nextTick(() => {
        this.$bvModal.hide(modalId)
      })
    },
    shallShowWarning () {
      return !this.forceContinue &&
        (this.register.avatarImage === null ||
        this.register.sectors.length === 0 ||
        this.register.roles.length === 0 ||
        this.register.skills.length === 0)
    },
    createUser () {
      this.forceContinue = true
      this.onRegister()
    },
    onRegister () {
      this.lockScreen()
      this.errorFormMessage = null

      if (this.shallShowWarning()) {
        this.unlockScreen()
        this.$bvModal.show('uncomplete-profile-warning')
        return
      }

      const data = this.loggedUser
      data.technician = this.register

      this.$store.dispatch('updateUser', EditableUserFactory.getEditableUser(data))
        .then(() => {
          this.unlockScreen()
          this.$store.dispatch('getUser')
            .then(() => {
              this.unlockScreen()
              this.$router.push({ name: 'dashboard-technician' })
            })
        })
        .catch((error) => {
          const responseData = error.response.data
          this.errorFormMessage = this.createFormErrors(responseData)

          const responseErrors = this.createFormFieldErrors(responseData)
          this.$refs.registerForm.setErrors(responseErrors)
          this.unlockScreen()
        })
    }
  }
}
</script>

<style lang="less">
  @import 'register.less';

  .column-image {
    background-image: url("../assets/img/login/background-login.jpg");
    background-size: cover;
  }
</style>
