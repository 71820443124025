<template>
  <b-container fluid class="min-vh-100">
    <b-row class="min-vh-100" align-h="center" align-v="stretch">
      <b-col cols="12" lg="6" class="column-image" order="1" order-lg="2">
        <div class="d-flex justify-content-center align-items-center h-100">
          <b-row class="justify-content-center">
            <b-col cols="6" md="8" lg="12">
              <img
                alt="Avify Logo"
                src="../assets/logos/logo_full_big.svg"
                class="p-3 p-md-2 p-lg-0 img-fluid" />
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="12" md="8" lg="6" order="2" order-lg="1">
        <b-row align-h="center" align-v="center" class="h-100">
          <b-col cols="12" lg="9" class="text-left">
            <div class="login-header my-5 h-100">
              <validation-observer ref="registerForm" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onRegister)"
                  autocomplete="off" novalidate>
                  <b-row align-h="center" align-v="center" class="h-100">
                    <b-col cols="12">
                      <onboarding-business-card
                        :register="register"
                        v-bind:showGoBack="false"
                      ></onboarding-business-card>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
              <hr/>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import OnboardingBusinessCard from '../components/Registration/OnboardingBusinessCard'
import EditableUserFactory from '@/domain/services/EditableUserFactory'

export default {
  name: 'CompleteBusiness',
  components: { OnboardingBusinessCard },
  data () {
    return {
      register: {
        business_name: null,
        business_cif: null,
        business_sector: [],
        business_address: null,
        business_zipcode: null,
        business_prefix: null,
        business_phone: null,
        business_location: null
      },
      errorFormMessage: null,
      forceContinue: false
    }
  },
  created () {
    this.$store.dispatch('getSectors')
    this.$store.dispatch('getRoles')
    this.$store.dispatch('getSkills')
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    }
  },
  methods: {
    stay (modalId) {
      this.$nextTick(() => {
        this.$bvModal.hide(modalId)
      })
    },
    createUser () {
      this.forceContinue = true
      this.onRegister()
    },
    onRegister () {
      this.lockScreen()
      this.errorFormMessage = null

      const data = this.loggedUser
      data.business = {
        name: this.register.business_name,
        cif: this.register.business_cif,
        sectors: this.register.business_sector,
        address: this.register.business_address,
        zipcode: this.register.business_zipcode,
        phoneNumber: {
          number: this.register.business_phone,
          prefix: this.register.business_prefix
        },
        location: this.register.business_location
      }

      const editableData = EditableUserFactory.getEditableUser(data)

      this.$store.dispatch('updateUser', editableData)
        .then(() => {
          this.$store.dispatch('getUser')
            .then(() => {
              this.unlockScreen()
              this.$router.push({ name: 'community' })
            })
        })
        .catch((error) => {
          const responseData = error.response.data
          this.errorFormMessage = this.createFormErrors(responseData)

          const responseErrors = this.createFormFieldErrors(responseData)
          this.$refs.registerForm.setErrors(responseErrors)
          this.unlockScreen()
        })
    }
  }
}
</script>

<style lang="less">
  @import 'register.less';

  .column-image {
    background-image: url("../assets/img/login/background-login.jpg");
    background-size: cover;
  }
</style>
