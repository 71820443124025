<template>
   <div>
      <div v-if="showGoBack">
         <b-link
            @click="$emit('onGoToStart')"
            class="text-light">
               &lt; Inicio | Registro negocios
         </b-link>
      </div>
      <div class="login-header mt-4 h-100">
         <h2>Completar mi información de empresa</h2>
         <p class="mb-2">
            Esta información nos permitirá ofrecerte un servicio de Avify
            adaptado a tu perfil.
         </p>
         <b-alert fade :show="showFormErrorAlert" variant="danger">
            {{ errorFormMessage }}
         </b-alert>
         <b-row>
            <b-col>
               <validation-provider name="nombre de la empresa"
                  v-slot="validationContext"
                  vid="business-name"
                  rules="required">
                  <b-form-group id="business-name" label-for="input-business-name">
                     <b-form-input
                        id="input-business-name"
                        name="input-business-name"
                        v-model="register.business_name"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-business-name"
                        placeholder="Nombre de la empresa *"
                     ></b-form-input>
                     <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
               </validation-provider>
            </b-col>
         </b-row>
         <b-row>
            <b-col>
               <validation-provider name="CIF"
                  v-slot="validationContext"
                  vid="business-cif"
                  rules="required|cif">
                  <b-form-group id="business-cif" label-for="input-business-cif">
                     <b-form-input
                        id="input-business-cif"
                        name="input-business-cif"
                        v-model="register.business_cif"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-business-cif"
                        placeholder="CIF *"
                     ></b-form-input>
                     <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
               </validation-provider>
            </b-col>
         </b-row>
         <b-row>
            <b-col>
               <validation-provider name="sectores"
                  v-slot="validationContext"
                  vid="business-sector">
                  <b-form-group id="input-sectors" label-for="input-sectors">
                      <ProfessionalSkillSelect
                        title="Sectores"
                        placeholder="Selecciona uno o varios sectores"
                        :options="sectorOptions"
                        :suggestions="true"
                        suggestionItem="sector"
                        :selectedOptions="register.business_sector"
                        eventName="removeSector"
                        v-bind:showHeader="false"
                        @onRemoveSector="onRemoveSector"
                        addEventName="addNewSector"
                        @addNewSector="onAddSector"
                        :state="getValidationSectorState(validationContext)"
                        :are-options-loaded="sectorOptions.length === 0"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                  </b-form-group>
               </validation-provider>
            </b-col>
         </b-row>
         <b-row>
            <b-col cols="8">
               <validation-provider name="dirección"
                  v-slot="validationContext"
                  vid="business-address"
                  rules="required">
                  <b-form-group id="business-address" label-for="input-business-address">
                     <b-form-input
                        id="input-business-address"
                        name="input-business-address"
                        v-model="register.business_address"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-business-address"
                        placeholder="Dirección (calle y número) *"
                     ></b-form-input>
                     <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
               </validation-provider>
            </b-col>
            <b-col>
               <validation-provider name="código postal"
                  v-slot="validationContext"
                  vid="business-zipcode"
                  rules="required">
                  <b-form-group id="business-zipcode" label-for="input-business-zipcode">
                     <b-form-input
                        id="input-business-zipcode"
                        name="input-business-zipcode"
                        v-model="register.business_zipcode"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-business-zipcode"
                        placeholder="Código postal *"
                     ></b-form-input>
                     <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
               </validation-provider>
            </b-col>
         </b-row>
         <b-row>
            <b-col>
               <validation-provider name="ciudad"
                  v-slot="validationContext"
                  vid="business-city"
                  rules="required">
                  <b-form-group id="business-city" label-for="input-business-city">
                     <GooglePlaces
                        :value="value"
                        :state=getValidationCityState(validationContext)
                        @selected="onChangeGoogleData"
                        @keypress="onKeyPressGoogle(validationContext)"
                     />
                     <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                     </b-form-invalid-feedback>
                  </b-form-group>
               </validation-provider>
            </b-col>
         </b-row>
         <b-row>
            <b-col cols=3>
               <validation-provider name="prefijo"
                  v-slot="validationContext"
                  vid="business-prefijo"
                  :rules="{ required: true, prefix: true }">
                  <b-form-group id="business-prefix" label-for="input-business-prefix">
                     <b-form-input
                        maxlength="4"
                        id="input-business-prefix"
                        name="input-business-prefix"
                        v-model="register.business_prefix"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-business-prefix"
                        placeholder="Prefijo *"
                     ></b-form-input>
                     <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                     </b-form-invalid-feedback>
                  </b-form-group>
               </validation-provider>
            </b-col>
            <b-col cols=9>
               <validation-provider name="teléfono"
                  v-slot="validationContext"
                  vid="business-phone"
                  rules="required">
                  <b-form-group id="business-phone" label-for="input-business-phone">
                     <b-form-input
                        id="input-business-phone"
                        name="input-business-phone"
                        v-model="register.business_phone"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-business-phone"
                        placeholder="Número de teléfono *"
                     ></b-form-input>
                     <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                     </b-form-invalid-feedback>
                  </b-form-group>
               </validation-provider>
               <p>
                  Tu número solo será visible por los equipos de Avify para que
                  puedan contactar contigo.
               </p>
            </b-col>
         </b-row>
         <div class="text-center">
            <b-button block type="submit" variant="primary" class="full-width my-4">
               Finalizar
            </b-button>
         </div>
      </div>
   </div>
</template>
<script>
/* eslint vue/no-mutating-props: 0 */

import ProfessionalSkillSelect from '../ProfessionalSkillSelect/ProfessionalSkillSelect'
import LocationFactory from '@/domain/services/LocationFactory'
import GooglePlaces from '../Places/GooglePlaces.vue'
export default {
  components: { ProfessionalSkillSelect, GooglePlaces },
  props: {
    register: Object,
    registerStep: Number,
    showGoBack: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      value: null,
      errorFormMessage: null,
      selectedCity: null
    }
  },
  created () {
    this.$store.dispatch('getSectors')
  },
  mounted () {
  },
  computed: {
    sectorOptions () {
      const elements = this.$store.getters.getSectorsList
      elements.unshift({ value: '', text: 'Selecciona uno o varios sectores' })
      return elements
    },
    showFormErrorAlert () {
      return this.errorFormMessage !== null
    }
  },
  methods: {
    getValidationCityState (obj) {
      if (!obj.validated) {
        return true
      }

      if (this.value === '' || this.value === null) {
        return false
      }

      return true
    },
    getValidationSectorState (obj) {
      if (!obj.validated) {
        return true
      }

      return this.register.business_sector.length > 0
    },
    goToStart () {
      this.registerStep = 1
    },
    onChangeGoogleData (evt) {
      const location = LocationFactory.getLocationByGoogle(evt)
      this.selectedCity = evt
      this.value = this.getFormattedLocationFromHit(location)
      this.register.business_location = location
    },
    getFormattedLocationFromHit (location) {
      return [location.name, location.administrative, location.country].join(', ')
    },
    onKeyPressGoogle (obj) {
      this.selectedCity = null
      this.register.business_location = null
      this.value = null
    },
    onRemoveSector (id) {
      this.register.business_sector.forEach((sector, index) => {
        if (sector.id === id) {
          this.register.business_sector.splice(index, 1)
        }
      })
    },
    onAddSector (sector) {
      const exists = this.register.business_sector.findIndex(
        item => item.id === sector.id
      )
      if (sector.id !== '' && exists === -1) {
        this.register.business_sector.push(sector)
      }
    }
  }
}
</script>
<style lang="less">
  .avatar-container {
     position: relative;
     .edit-photo {
        position: absolute;
        bottom: 5px;
        right: 5px;
     }
  }
</style>
