<template>
  <public-template>
    <template v-slot:public-template>
      <b-row class="flex-column flex-sm-row flex-lg-row" align-h="center">
        <b-col col xs="12" sm="8" lg="4">
          <card v-if="recoveryPasswordConfirmation">
            <template v-slot:card-body>
              <img src="../assets/icons/icon-send-email.svg" class="mb-4">
              <h5>Confirma tu email</h5>
              <p>Hemos enviado un email a:</p>
              <p>{{ recoveryPassword.email }}</p>
              <p>Haz click en el link de confirmación del email para activar tu cuenta de Avify.</p>
              <b-link :to="{ name: 'login'}" class="btn btn-secondary mt-3">De acuerdo</b-link>
            </template>
          </card>

          <b-card
            title="Recupera tu contraseña"
            class="recovery-password-form"
            v-else
          >
            <b-card-body>
              <validation-observer ref="recoveryPasswordForm" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onRecoveryPassword)" autocomplete="off" novalidate>
                  <b-row>
                    <b-col>
                      <validation-provider name="correo electrónico" rules="required|email" v-slot="validationContext">
                        <b-form-group id="input-username" label-for="input-username">
                          <b-form-input
                            id="input-username"
                            name="input-username"
                            v-model=recoveryPassword.email
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                            placeholder="Correo electrónico"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <b-button block type="submit" variant="primary" class="full-width mb-3">
                      Continuar
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
              <b-row :no-gutters="true">
                <b-col class="d-flex justify-content-end">
                  <b-link :to="{ name: 'login'}">Volver</b-link>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </public-template>
</template>

<script>
import Card from '../components/Card/Card'
export default {
  name: 'RequestPasswordRecovery',
  components: { Card },
  data () {
    return {
      recoveryPassword: {
        email: null
      },
      recoveryPasswordConfirmation: false,
      errorFormMessage: null
    }
  },
  methods: {
    onRecoveryPassword () {
      this.$store.dispatch('recoveryUserPassword', this.recoveryPassword)
        .then(() => {
          this.recoveryPasswordConfirmation = true
        })
    }
  }
}
</script>

<style lang="less">
  @import 'requestPasswordRecovery.less';
</style>
