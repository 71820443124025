<template>
    <card>
        <template v-slot:card-body>
          <img src="../../assets/icons/icon-send-email.svg" class="mb-4">
          <h5>Confirma tu email</h5>
          <p>Hemos enviado un email a:</p>
          <p>{{ username }}</p>
          <p>Haz click en el link de confirmación del email para activar tu cuenta de Avify.</p>
          <b-link :to="{ name: 'login'}" class="btn btn-secondary mt-3">De acuerdo</b-link>
        </template>
    </card>
</template>
<script>
import Card from '../Card/Card'
export default {
  name: 'EmailSentCard',
  props: {
    username: {
      type: String
    }
  },
  components: { Card }
}
</script>
